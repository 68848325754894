import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/cyR03HfJoAs">
    <SEO title="Messed Up - Romans: Principles" />
  </Layout>
)

export default SermonPost
